import ContactForm from "@/components/contact-form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { getPolicyPageUrl, getSocialLinkUrl } from "@/lib/utils";
import type { Category, Shop, Tag, User } from "@/types";
import { Mail, Phone } from "lucide-react";
import { useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";

interface Props {
  shop: Shop;
  user?: User | null;
  shopCategories?: Category[];
  shopTags?: Tag[];
}

const Footer = ({ shop, user, shopCategories = [], shopTags = [] }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <footer className="bg-kp-secondary text-kp-white py-16">
        <div className="container mx-auto px-4 grid md:grid-cols-4 gap-8">
          <div>
            <h2 className="text-2xl font-bold mb-4 text-white">{shop.name}</h2>
            <p className="text-sm text-white mb-4">{shop.slogan}</p>
            <div className="flex gap-4">
              {shop?.whatsapp && (
                <a
                  href={getSocialLinkUrl("whatsapp", shop?.whatsapp)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-kp-white hover:text-kp-accent transition-colors"
                  aria-label="WhatsApp">
                  <FaWhatsapp className="size-6" />
                </a>
              )}

              {shop.facebook && (
                <a
                  href={getSocialLinkUrl("facebook", shop?.facebook)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-kp-white hover:text-kp-accent transition-colors"
                  aria-label="Facebook">
                  <FaFacebook className="size-6" />
                </a>
              )}

              {shop.instagram && (
                <a
                  href={getSocialLinkUrl("instagram", shop?.instagram)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-kp-white hover:text-kp-accent transition-colors"
                  aria-label="Instagram">
                  <FaInstagram className="size-6" />
                </a>
              )}

              {shop.twitter && (
                <a
                  href={getSocialLinkUrl("twitter", shop?.twitter)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-kp-white hover:text-kp-accent transition-colors"
                  aria-label="X/Twitter">
                  <FaTwitter className="size-6" />
                </a>
              )}

              {shop.tiktok && (
                <a
                  href={getSocialLinkUrl("tiktok", shop?.tiktok)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-kp-white hover:text-kp-accent transition-colors"
                  aria-label="TikTok">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-6"
                    fill="currentColor"
                    viewBox="0 0 16 16">
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                  </svg>
                </a>
              )}
            </div>
          </div>

          <div>
            <h3 className="font-semibold mb-4 text-white/60">Popular</h3>
            <ul className="space-y-2">
              {shopCategories.map((item) => (
                <li key={item.id}>
                  <a
                    href={`/shops/${shop.slug}?category=${item.slug}`}
                    className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                    {item.name}
                  </a>
                </li>
              ))}
              {shopTags.map((item) => (
                <li key={item.id}>
                  <a
                    href={`/shops/${shop.slug}?tag=${item.slug}`}
                    className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                    {item.name}
                  </a>
                </li>
              ))}

              {shopTags.map((tag) => (
                <li key={tag.id}>
                  <a
                    href={`/shops/${shop.slug}?tag=${tag.slug}`}
                    className="hover:underline underline-offset-4">
                    {tag.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="font-semibold mb-4 text-white/60">Main Store</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href={`tel:${shop.phone}`}
                  className="text-kp-white hover:text-kp-accent transition-colors flex items-center gap-2">
                  <Phone size={16} /> {shop.phone}
                </a>
              </li>
              {shop.phone2 && (
                <li>
                  <a
                    href={`tel:${shop.phone2}`}
                    className="hover:underline underline-offset-4 flex items-center gap-2">
                    <Phone size={16} />
                    <span>{shop.phone2}</span>
                  </a>
                </li>
              )}
              {shop.phone3 && (
                <li>
                  <a
                    href={`tel:${shop.phone3}`}
                    className="hover:underline underline-offset-4 flex items-center gap-2">
                    <Phone size={16} />
                    <span className="">{shop.phone3}</span>
                  </a>
                </li>
              )}
              <li>
                <a
                  href={`mailto:${shop.email}`}
                  className="text-kp-white hover:text-kp-accent transition-colors flex items-center gap-2">
                  <Mail size={16} /> {shop.email}
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold mb-4 text-white/60">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href={getPolicyPageUrl("privacy-policy", shop)}
                  className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={getPolicyPageUrl("terms-of-service", shop)}
                  className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  href={getPolicyPageUrl("returns-policy", shop)}
                  className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                  Return, Shipping, & Refund Policy
                </a>
              </li>
              <li>
                {/* TODO: a dialog form for receiving contact form requests */}
                <button
                  onClick={() => setOpen(true)}
                  className="text-kp-white hover:text-kp-accent hover:underline transition-colors border-none">
                  Get In Touch
                </button>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      {/* contact modal */}
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Get in Touch With Us</DialogTitle>
          </DialogHeader>
          <ContactForm
            onSuccess={() => {
              setTimeout(() => {
                setOpen(false);
              }, 1500);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Footer;
